import iphone_img from "../assets/images/iphone-15.png";
import iphone_2 from "../assets/images/iphone_2.png";
import iphone_3 from "../assets/images/iphone_3.png";
import iphone_4 from "../assets/images/iphone_4.png";
import check_icon from "../assets/images/circle_check.svg";
import video_thumb from "../assets/images/video_thumb_1.png";
import curve_left from "../assets/images/arrow_curve_left.png";
import curve_right from "../assets/images/arrow_curve_right.png";
import manage_svg_com from "../assets/images/management-svgrepo-com.png";
import communicate_icon from "../assets/images/user_green.png";
import hand_shake from "../assets/images/hand_shake.png";
import student_icons from "../assets/images/doctor_icon.png";
import time_saving from "../assets/images/time_saving.png";
import inclusion from "../assets/images/inclusion.png";
import list_candidates from "../assets/images/list_candidates.png";
import list_candidate_1 from "../assets/images/list_candidates_1.png";
import college_logo from "../assets/images/mycollege_cost_logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/youtube";
type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  url: string;
};
export const VideoBox = (props: Props) => {
  const { url, className } = props;
  return (
    <div className={className}>
      <div className={`player-wrapper rounded-lg max-md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          loop={true}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
      <div className={`player-wrapper rounded-lg md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height={220}
          loop={true}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
    </div>
  );
};
type IProps = {
  title: string;
  desc: string;
};
type ItemProps = {
  title: string;
  desc: string;
  src: string;
};
const ItemBox = ({ title, desc }: IProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <h6 className="text-[#2B574F] max-md:text-lg md:text-xl xl:text-2xl font-black flex">
        <img src={check_icon} alt="" />
        {title}
      </h6>
      <p className="max-md:text-sm md:text-base xl:text-lg font-light text-[#646464]">
        {desc}
      </p>
    </div>
  );
};
const ItemSrcBox = ({ src, title, desc }: ItemProps) => {
  return (
    <div>
      <div className="md:w-[416px] grid font-work_sans mx-auto">
        <div className="grid h-[104px]">
          <div>
            <img src={src} alt="" className="mx-auto" />
          </div>
          <h6 className="text-[#2B574F] font-extrabold xl:text-2xl text-center">
            {title}
          </h6>
        </div>
        <p className="font-light text-center">{desc}</p>
      </div>
    </div>
  );
};
const ItemSrcBoxMobile = ({ src, title, desc }: ItemProps) => {
  return (
    <div>
      <div className="md:w-[416px] grid font-work_sans mx-auto">
        <div className="grid h-[104px]">
          <div>
            <img src={src} alt="" className="mx-auto" />
          </div>
          <h6 className="text-[#2B574F] font-extrabold text-xl text-center">
            {title}
          </h6>
        </div>
        <p className="font-light text-center text-[14px]">{desc}</p>
      </div>
    </div>
  );
};
export const StudentParents = () => {
  return (
    <>
      <div className="max-md:hidden grid gap-y-[40px] font-work_sans pb-[40px]">
        <section className="student_parent_hero_bg w-100 md:h-[622px] flex items-center justify-center relative">
          <div className="flex justify-center p-8 space-y-2 hero_caption space-x-4">
            <div className="space-y-3">
              <div className="flex items-center space-x-4">
                <div>
                  <img src={college_logo} alt="" />
                </div>
                <h6 className="text-xl font-extrabold text-white">
                  MyCollegeCosts
                </h6>
              </div>
              <h6 className="text-[#fff] w-[550px] text-center font-poppins font-normal">
                A tool to help students receive the best possible financial aid
                award
              </h6>
            </div>
            {/* <div>
              <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg">
                Register Now
              </button>
            </div> */}
          </div>
        </section>
        <section className="flex justify-center xl:space-x-[75px] md:space-x-3 md:px-3">
          <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[1100px] md:min-h-[448px] space-y-5">
            <div className="space-y-2">
              <h5 className="text-[#3FC6AD] text-2xl font-bold font-opensans">
                Before you begin… please note that MyCollegeCosts may be for you
                if you meet the following requirements.
              </h5>
              <h6 className="font-bold font-opensans text-[#2B574F] text-2xl">
                You...
              </h6>
            </div>
            <ul className=" font-opensans font-light list-disc list-inside text-[#646464] space-y-3">
              <li className="text-xl">
                Want to enroll in a four-year college or university in the Fall
                of 2025.
              </li>
              <li className="text-xl">Are a resident of the United States.</li>
              <li className="text-xl">
                Plan to enroll as a Full-Time Student.
              </li>
              <li className="text-xl">
                Will complete and submit your Free Application for Federal
                Student Aid (FAFSA).
              </li>
              <li className="text-xl">
                Will be dependent on a parent or guardian for financial support.
              </li>
              <li className="text-xl">
                Are open to sharing selected information from your FAFSA on the
                MyCollegeCosts application.
              </li>
              <li className="text-xl">
                Can complete a brief demographic form.
              </li>
            </ul>
            <p className="text-xl font-bold font-opensans text-[#3FC6AD]">
              If so...Keep going! MyCollegeCosts may save you thousands of
              dollars! 
            </p>
          </div>
        </section>
        <section className="xl:flex xl:justify-center xl:space-x-[75px] xl:space-x-8 md:px-3 font-opensans">
          <div className="flex justify-center items-center">
            <VideoBox
              url="https://youtu.be/meZHgbIihZo"
              className="md:w-[90vw] xl:w-[670px] md:h-[40vw] xl:h-[350px] rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[670px] space-y-3 text-[#646464] md:mt-10">
              <p className="text-xl font-light ">
                You start the financial aid conversation with the colleges of your
                choice
              </p>
              <p className="text-xl font-light">
                You tell the schools what you can afford and what it will take for
                you to enroll
              </p>
              <ul className=" text-xl font-light list-disc list-inside text-[#646464] space-y-2">
                You request a quick response and, thus, have plenty of time to
                <li className="pt-4">Decide to attend or not</li>
                <li>Choose another school if necessary</li>
                <li>More effectively plan how to pay</li>
              </ul>
              <p className="font-light text-xl">
                MyCollegeCosts gives you the detailed data you need to determine
                what you can really afford. AND Provides you with sample letters
                you can present to the colleges of your choice to enhance your
                chance of getting the dollars you need!
              </p>
              {/* <div>
                <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg">
                  Register Now
                </button>
              </div> */}
            </div>
          </div>
        </section>
        <section className="flex justify-center xl:space-x-[75px] md:space-x-3 md:px-3">
          <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[1100px] md:min-h-[448px] space-y-5">
            <h5 className="text-[#3FC6AD] text-2xl font-bold font-opensans">
              Steps needed to present your own student aid award.
            </h5>
            <ul className=" font-opensans font-light list-disc list-inside text-[#646464] space-y-3 text-xl">
              <li className="text-xl">
                You first must submit your free application for federal student
                aid (FASFA) after December 1st.
              </li>
              <li className="text-xl">
                You will receive, The FAFSA Submission Summary (FSS) from the
                Department of Education (DOE) with your eligibility information
                and Student Aid Index number (SAI).
              </li>
              <li className="text-xl">
                You go online and register with MyCollegeCosts.
              </li>
              <li className="text-xl">
                You add information from your FSS – including your SAI number.
              </li>
              <li className="text-xl">
                You will receive an estimated financial aid award for each
                institution you listed.
              </li>
              <li className="text-xl">
                You will receive a set of paragraphs supporting your request for
                each institution listed.
              </li>
              <li className="text-xl">
                Each award recommendation will combine your SAI/EFC and eligible
                Federal and State resources to apply toward each institutions
                institution’s bill.
              </li>
              <li className="text-xl">
                Your identified GAP will be the scholarship amount you will
                request from each institution you have listed.
              </li>
              <li className="text-xl">
                You forward your suggested financial award within 10 days of
                receiving your estimated MyCollegeCosts award.
              </li>
              <li>
                Your letter explains the basis for your request and asks for a
                response within 20 – 30 days.
              </li>
              <li>
                Your letter explains that your request will make their
                institution an affordable option.
              </li>
            </ul>
            {/* <div>
              <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg">
                Register Now
              </button>
            </div> */}
          </div>
        </section>
      </div>
      <div className="grid px-3 py-4 md:hidden gap-y-4 font-work_sans">
        <section className="flex justify-center xl:space-x-[75px] md:space-x-3 md:px-3">
          <div className="border-shadow p-4 md:w-[90vw] xl:w-[1100px] md:min-h-[448px] space-y-5">
            <div className="space-y-2">
              <h5 className="text-[#3FC6AD] text-md font-bold font-opensans">
                Before you begin… please note that MyCollegeCosts may be for you
                if you meet the following requirements.
              </h5>
              <h6 className="font-bold font-opensans text-[#2B574F] text-lg">
                You...
              </h6>
            </div>
            <ul className=" font-opensans font-light list-disc list-inside text-[#646464] space-y-3">
              <li className="text-md">
                Want to enroll in a four-year college or university in the Fall
                of 2025.
              </li>
              <li className="text-md">Are a resident of the United States.</li>
              <li className="text-md">
                Plan to enroll as a Full-Time Student.
              </li>
              <li className="text-md">
                Will complete and submit your Free Application for Federal
                Student Aid (FAFSA).
              </li>
              <li className="text-md">
                Will be dependent on a parent or guardian for financial support.
              </li>
              <li className="text-md">
                Are open to sharing selected information from your FAFSA on the
                MyCollegeCosts application.
              </li>
              <li className="text-md">
                Can complete a brief demographic form.
              </li>
            </ul>
            <p className="text-md font-bold font-opensans text-[#3FC6AD]">
              If so...Keep going! MyCollegeCosts may save you thousands of
              dollars! 
            </p>
          </div>
        </section>
        <section className="flex flex-col justify-center space-y-3">
          <div className="flex items-center">
            <VideoBox
              url="https://youtu.be/meZHgbIihZo"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex justify-center items-center">
            <div className="border-shadow p-4 md:w-[90vw] xl:w-[670px] space-y-3 text-[#646464] md:mt-10">
              <p className="text-md font-light ">
                You start the financial aid conversation with the colleges of your
                choice
              </p>
              <p className="text-md font-light">
                You tell the schools what you can afford and what it will take for
                you to enroll
              </p>
              <ul className="text-md font-light list-disc list-inside text-[#646464] space-y-2">
                You request a quick response and, thus, have plenty of time to
                <li className="pt-4 text-md">Decide to attend or not</li>
                <li>Choose another school if necessary</li>
                <li>More effectively plan how to pay</li>
              </ul>
              <p className="font-light text-md">
                MyCollegeCosts gives you the detailed data you need to determine
                what you can really afford. AND Provides you with sample letters
                you can present to the colleges of your choice to enhance your
                chance of getting the dollars you need!
              </p>
              {/* <div>
                <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 w-full rounded-lg">
                  Register Now
                </button>
              </div> */}
            </div>
          </div>
        </section>
        <section className="flex flex-col space-y-4">
          <div className="border-shadow p-4 md:w-[90vw] xl:w-[1100px] md:min-h-[448px] space-y-5">
            <h5 className="text-[#3FC6AD] text-md font-bold font-opensans">
              Steps needed to present your own student aid award.
            </h5>
            <ul className=" font-opensans font-light list-disc list-inside text-[#646464] space-y-3 text-md">
              <li className="text-md">
                You first must submit your free application for federal student
                aid (FASFA) after December 1st.
              </li>
              <li className="text-md">
                You will receive, The FAFSA Submission Summary (FSS) from the
                Department of Education (DOE) with your eligibility information
                and Student Aid Index number (SAI).
              </li>
              <li className="text-md">
                You go online and register with MyCollegeCosts.
              </li>
              <li className="text-md">
                You add information from your FSS – including your SAI number.
              </li>
              <li className="text-md">
                You will receive an estimated financial aid award for each
                institution you listed.
              </li>
              <li className="text-md">
                You will receive a set of paragraphs supporting your request for
                each institution listed.
              </li>
              <li className="text-md">
                Each award recommendation will combine your SAI/EFC and eligible
                Federal and State resources to apply toward each institutions
                institution’s bill.
              </li>
              <li className="text-md">
                Your identified GAP will be the scholarship amount you will
                request from each institution you have listed.
              </li>
              <li className="text-md">
                You forward your suggested financial award within 10 days of
                receiving your estimated MyCollegeCosts award.
              </li>
              <li className="text-md">
                Your letter explains the basis for your request and asks for a
                response within 20 – 30 days.
              </li>
              <li className="text-md">
                Your letter explains that your request will make their
                institution an affordable option.
              </li>
            </ul>
            {/* <div>
              <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 w-full rounded-lg">
                Register Now
              </button>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
};
